<template>
  <section class="fdb-block" style="background-image: url(imgs/hero/blue.svg);">
    <div class="col-md-5 mx-auto">
      <div class="card rounded shadow-lg">
        <div class="card-body">
          <div class="row text-center">
            <div class="col" style="width:100px; max-height:100px;">
              <img src="/images/siged2.png" alt="homepage" class="dark-logo" style="max-width:100%; max-height:100%;" />
            </div>
          </div>
          <div class="card-title text-center">
            <h2>{{ title }}</h2>
          </div>
          <br />
          <form v-on:submit.prevent="enviarCorreoRecuperacion()">
            <div class="form-group">
              <label>Correo electrónico</label>
              <input
                type="email"
                name="Email"
                class="form-control"
                v-model="item.email"
                required
                autofocus
              />
            </div>
            <br />
            <div v-if="message">
              <v-progress-circular
                v-if="showProgress"
                :size="50"
                :width="5"
                color="blue"
                indeterminate
              ></v-progress-circular>
              <p class="alert alert-warning" role="alert">{{ message }}</p>
            </div>
            <div class="row text-center">
              <div class="col-6">
                <button type="submit" class="btn btn-primary float-center">{{ buttontext }}</button>
              </div>
              <div class="col-6">
                <router-link :to="{ name: 'Signin' }" class="btn btn-secondary float-center">Cancelar</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template> 

<script>
//import { VProgressCircular } from 'vuetify/lib';

//import toastr from 'toastr';
import { wait } from '../../utils';

export default {
  components: {
    //VProgressCircular
  },
  data() {
    return {
      add: true, //Create a new document
      close: true, // Close the page and return to list
      title: "",
      item: {},
      message: "",
      buttontext: "",
      showProgress: false
    };
  },
  computed: {
    company() {
      return this.$store.state.company;
    },
    user() {
      return this.$store.state.user;
    },
    cancelButtonVisibility() {
      if (this.addInd) {
        return "visibility:hidden";
      } else {
        return "visibility:visible";
      }
    }
  },
  created: function() {
    this.title = "Recuperación de Contraseña";
    this.buttontext = "Aceptar";
  },
  methods: {
    setClose(close) {
      this.close = close;
    },
    enviarCorreoRecuperacion() {
      this.item.host = window.location.origin;
      this.message = 'Validando su correo electrónico. Espere por favor ..';
      this.showProgress = true;
      let uri = "/usuarios/securityrecover";
      this.axios
        .post(uri, this.item)
        //.then(wait(10000))
        .then(response => {
          this.$router.replace({ name: "PwdEmail", params: { email: response.data.email } });
        })
        .catch(err => {
          console.log(err);
          this.message = "La dirección de correo electrónico no está registrada en Siged.";
          this.showProgress = false;
        });
    }
  }
}; //FIN DE SCRIPT
</script>

  <style>
.warning {
  color: #ff0000;
}
</style>